<template>
    <article class="main-section section-half">
        <div class="section-component">
            <header class="section-header">
                <h3 class="header-title">알림공간</h3>
            </header>
            <div class="section-content">
                <div class="text-items text-items-divider">
                    <!-- text-item -->
                    <div v-for="(item, index) in renderItems" class="text-item" :key="index">
                        <div class="item-meta">{{ getDcdName(item.boardTypeDcd) }}</div>
                        <h4 class="item-title swcc-cursor-pointer" @click="goNotice(item.bno)">{{ item.boardNm }}</h4>
                        <p class="item-description swcc-text-skip" style="max-width: 600px; max-height: 200px" :title="item.boardContents">
                            {{ item.boardContents }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </article>
</template>

<script>
import {computed, ref} from 'vue';
import {getItems, lengthCheck, replaceAll} from '@/assets/js/common.utils';
import ApiService from '@/assets/js/api.service';
import {getDcdName} from '@/assets/js/domain.code';
import {useRouter} from 'vue-router';

export default {
    name: 'MainNotice',
    components: {},
    setup() {
        const router = useRouter();
        const items = ref([]);

        ApiService.query('/v1/boards/notices').then(res => {
            if (lengthCheck(res)) {
                items.value = getItems(res);
            }
        });

        const renderItems = computed(() =>
            items.value.map(x => ({...x, boardContents: replaceAll(x.boardContents.replace(/(<([^>]+)>)/gi, ''), '&nbsp;', '')})),
        );

        const goNotice = bno => {
            router.push({name: 'NoticeView', params: {bno}});
        };

        return {
            goNotice,
            getDcdName,
            renderItems,
        };
    },
};
</script>

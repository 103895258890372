<template>
    <main class="swcluster-main" id="swcluster-home">
        <div class="page-body">
            <div class="page-contents">
                <!-- main-section:section-visual -->
                <MainBanner />
                <!-- main-section:section-flow -->
                <MainFoothold />
                <!-- main-section:section-half -->
                <article class="main-section section-half">
                    <div class="section-component">
                        <header class="section-header">
                            <h3 class="header-title">SW융합 클러스터</h3>
                        </header>
                        <div class="section-content">
                            <p class="description">
                                <br />SW융합 클러스터는 SW기술 관련 기업이 모여있는 집적 지역입니다. <br /><br />SW융합 생태계 활성화를 위해 SW수요기업과 대학,
                                그리고 연구소 등과 유기적 으로 <br />연계·협력·협업하여 SW융합 R&D 활동이 활발한 지역을 지칭합니다. <br /><br />SW 융합 R&D를
                                중심으로 지역 특화산업에 SW 융합 기술이 더해져 <br />새로운 기술·산업·시장 발굴과 벤처 창업 및 일자리 창출을 주도하는 <br />지역
                                경제 견인 기지의 기능을 수행합니다.
                            </p>
                            <!--                            <div class="links">-->
                            <!--                                <a href="javascript:" class="link">사업개요</a>-->
                            <!--                                <a href="javascript:" class="link">센터소개 <i class="icon-link"></i></a>-->
                            <!--                            </div>-->
                        </div>
                    </div>
                </article>
                <!-- main-section:section-half -->
                <article class="main-section section-half">
                    <div class="section-component">
                        <header class="section-header">
                            <span class="header-subtitle">원천 데이터 수집</span>
                            <h3 class="header-title">대구・제주의 초광역 <br />도시데이터 수집</h3>
                            <p class="header-description">대구와 제주의 안전・복지・환경・경제 4개분야의 <br />인공지능 데이터를 수집하고 연계합니다.</p>
                        </header>
                        <div class="section-content">
                            <div class="content-card-container">
                                <div class="content-card-wrapper">
                                    <!-- content-card -->
                                    <div class="content-card">
                                        <div class="card-image"><img src="@/assets/images/main/main_section_2_01.png" alt="" /></div>
                                        <div class="card-body"><h4 class="card-title">인공지능 도시 데이터</h4></div>
                                    </div>
                                    <!-- content-card -->
                                    <div class="content-card">
                                        <div class="card-image"><img src="@/assets/images/main/main_section_2_02.png" alt="" /></div>
                                        <div class="card-body"><h4 class="card-title">초광역 도시 데이터</h4></div>
                                    </div>
                                    <!-- content-card -->
                                    <div class="content-card">
                                        <div class="card-image"><img src="@/assets/images/main/main_section_2_03.png" alt="" /></div>
                                        <div class="card-body"><h4 class="card-title">공공기관 데이터</h4></div>
                                    </div>
                                    <!-- content-card -->
                                    <div class="content-card">
                                        <div class="card-image"><img src="@/assets/images/main/main_section_2_04.png" alt="" /></div>
                                        <div class="card-body"><h4 class="card-title">참여기업 요청 데이터</h4></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
                <!-- main-section:section-half -->
                <article class="main-section section-half">
                    <div class="section-component">
                        <header class="section-header">
                            <h3 class="header-title">데이터 통합관리 체계</h3>
                        </header>
                        <div class="section-content">
                            <div class="text-items">
                                <!-- text-item -->
                                <div class="text-item">
                                    <h4 class="item-title">데이터 레이크</h4>
                                    <p class="item-description">
                                        데이터 허브 포털과 API 활용을 통해 클라우드 서비스의 연계를 구성합니다. <br />또한 표준화 및 품질을 검증하고, SW 제작용
                                        데이터 가공을 처리합니다.
                                    </p>
                                </div>
                                <!-- text-item -->
                                <div class="text-item">
                                    <h4 class="item-title">데이터 마트</h4>
                                    <p class="item-description">
                                        대구와 제주의 신서비스 개발을 위한 개별 데이터 마트를 구성합니다. <br />
                                        <span class="text-muted">특정 데이터 서비스를 분석에 필요한 정보로 구성하는 데이터 집합체</span>
                                    </p>
                                </div>
                                <!-- text-item -->
                                <div class="text-item">
                                    <h4 class="item-title">데이터 허브</h4>
                                    <p class="item-description">
                                        데이터 콘텐츠 허브를 통해 데이터를 소개하고 다운로드할 수 있습니다. <br />또한 시각화 분석 사례들을 제공합니다.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
                <!-- main-section:section-banner -->
                <article class="main-section section-banner">
                    <div class="section-component">
                        <div class="section-content">
                            <div class="banner-container" id="banner-datahub">
                                <div class="banner-image">
                                    <div class="image-obj-01" style="transform: translate(0, 0)">
                                        <img src="@/assets/images/main/main_section_3_01.png" alt="" />
                                    </div>
                                    <div class="image-obj-02" style="transform: translate(0, 0)">
                                        <img src="@/assets/images/main/main_section_3_02.png" alt="" />
                                    </div>
                                </div>
                                <div class="banner-content">
                                    <span class="content-subtitle">데이터 허브</span>
                                    <h3 class="content-title">대구와 제주의 공공데이터 현황과 <br />시각화 분석 사례를 확인하세요.</h3>
                                    <!--                                    <div class="content-nav">-->
                                    <!--                                        <button class="btn-nav"><i class="icon-arrow">다음</i></button>-->
                                    <!--                                    </div>-->
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
                <!-- main-section:section-flow -->
                <article class="main-section section-flow">
                    <div class="section-content">
                        <div class="square-card-container">
                            <div class="square-card-wrapper">
                                <!-- square-card -->
                                <figure class="square-card">
                                    <div class="card-image">
                                        <img src="@/assets/images/main/main_section_4_02.png" alt="" class="image" />
                                    </div>
                                    <figcaption class="card-content">골프 부킹을 위한 인공지능 <br />시스템 분석 사례</figcaption>
                                </figure>
                                <!-- square-card -->
                                <figure class="square-card">
                                    <div class="card-image">
                                        <img src="@/assets/images/main/main_section_4_03.png" alt="" class="image" />
                                    </div>
                                    <figcaption class="card-content">인공지능을 통한 <br />분석 세미나</figcaption>
                                </figure>
                                <!-- square-card -->
                                <figure class="square-card">
                                    <div class="card-image">
                                        <img src="@/assets/images/main/main_section_4_04.png" alt="" class="image" />
                                    </div>
                                    <figcaption class="card-content">식품 공정과 <br />인공지능</figcaption>
                                </figure>
                                <!-- square-card -->
                                <figure class="square-card">
                                    <div class="card-image">
                                        <img src="@/assets/images/main/main_section_4_05.png" alt="" class="image" />
                                    </div>
                                    <figcaption class="card-content">EDA 식물반도체 <br />공정 자동화</figcaption>
                                </figure>
                                <!-- square-card -->
                                <figure class="square-card">
                                    <div class="card-image">
                                        <img src="@/assets/images/main/main_section_4_06.png" alt="" class="image" />
                                    </div>
                                    <figcaption class="card-content">대구 헬스 <br />데이터 사용</figcaption>
                                </figure>
                                <!-- square-card -->
                                <figure class="square-card">
                                    <div class="card-image">
                                        <img src="@/assets/images/main/main_section_4_07.png" alt="" class="image" />
                                    </div>
                                    <figcaption class="card-content">4개 분야별 신서비스 개발을 <br />위한 데이터 마트 구성</figcaption>
                                </figure>
                                <!-- square-card -->
                                <figure class="square-card">
                                    <div class="card-image">
                                        <img src="@/assets/images/main/main_section_4_01.png" alt="" class="image" />
                                    </div>
                                    <figcaption class="card-content">식품 공정과 <br />인공지능</figcaption>
                                </figure>
                                <!-- square-card -->
                                <figure class="square-card">
                                    <div class="card-image">
                                        <img src="@/assets/images/main/main_section_4_02.png" alt="" class="image" />
                                    </div>
                                    <figcaption class="card-content">골프 부킹을 위한 인공지능 <br />시스템 분석 사례</figcaption>
                                </figure>
                                <!-- square-card -->
                                <figure class="square-card">
                                    <div class="card-image">
                                        <img src="@/assets/images/main/main_section_4_03.png" alt="" class="image" />
                                    </div>
                                    <figcaption class="card-content">인공지능을 통한 <br />분석 세미나</figcaption>
                                </figure>
                                <!-- square-card -->
                                <figure class="square-card">
                                    <div class="card-image">
                                        <img src="@/assets/images/main/main_section_4_04.png" alt="" class="image" />
                                    </div>
                                    <figcaption class="card-content">식품 공정과 <br />인공지능</figcaption>
                                </figure>
                                <!-- square-card -->
                                <figure class="square-card">
                                    <div class="card-image">
                                        <img src="@/assets/images/main/main_section_4_05.png" alt="" class="image" />
                                    </div>
                                    <figcaption class="card-content">EDA 식물반도체 <br />공정 자동화</figcaption>
                                </figure>
                            </div>
                        </div>
                    </div>
                </article>
                <!-- main-section:section-service -->
                <MainService />
                <!-- main-section:section-half -->
                <MainNotice />
            </div>
        </div>
    </main>
</template>

<script>
import MainBanner from '@/components/main/MainBanner';
import MainFoothold from '@/components/main/MainFoothold';
import MainService from '@/components/main/MainService';
import MainNotice from '@/components/main/MainNotice';

export default {
    name: 'Main',
    components: {MainNotice, MainService, MainFoothold, MainBanner},
};
</script>

<template>
    <article class="main-section section-flow">
        <header class="section-header">
            <h3 class="header-title">대구와 제주 <br />SW융합의 거점을 만들다.</h3>
        </header>
        <div class="section-content">
            <div class="square-card-container">
                <swiper
                    class="square-card-wrapper swcc-fixed-width-large"
                    :modules="modules"
                    :loop="true"
                    :slidesPerView="5"
                    :spaceBetween="10"
                    :allowTouchMove="false"
                    :speed="1500"
                    :autoplay="{
                        delay: 1500,
                        disableOnInteraction: false,
                    }">
                    <swiper-slide>
                        <figure class="square-card">
                            <div class="card-image">
                                <img src="@/assets/images/main/main_section_1_01.png" alt="" class="image" />
                                <div class="image-content">
                                    <span class="title-en">Data Consulting</span>
                                    <span class="title">데이터 컨설팅</span>
                                </div>
                            </div>
                            <figcaption class="card-content">데이터 소개 및 다운로드 융합기술</figcaption>
                        </figure>
                    </swiper-slide>

                    <swiper-slide>
                        <figure class="square-card">
                            <div class="card-image">
                                <img src="@/assets/images/main/main_section_1_02.png" alt="" class="image" />
                                <div class="image-content">
                                    <span class="title-en">Data Hub</span>
                                    <span class="title">데이터 허브</span>
                                </div>
                            </div>
                            <figcaption class="card-content">데이터 소개 및 다운로드 시각화분석 소개</figcaption>
                        </figure>
                    </swiper-slide>
                    <swiper-slide>
                        <figure class="square-card">
                            <div class="card-image">
                                <img src="@/assets/images/main/main_section_1_03.png" alt="" class="image" />
                                <div class="image-content">
                                    <span class="title-en">Data Lake</span>
                                    <span class="title">데이터 레이크</span>
                                </div>
                            </div>
                            <figcaption class="card-content">도시데이터 수집・처리・적재・표준화・검증</figcaption>
                        </figure>
                    </swiper-slide>
                    <swiper-slide>
                        <figure class="square-card">
                            <div class="card-image">
                                <img src="@/assets/images/main/main_section_1_04.png" alt="" class="image" />
                                <div class="image-content">
                                    <span class="title-en">Data Mart</span>
                                    <span class="title">데이터 마트</span>
                                </div>
                            </div>
                            <figcaption class="card-content">신서비스 개발을 위한 4개 분야 데이터 마트</figcaption>
                        </figure>
                    </swiper-slide>
                    <swiper-slide>
                        <figure class="square-card">
                            <div class="card-image">
                                <img src="@/assets/images/main/main_section_1_05.png" alt="" class="image" />
                                <div class="image-content">
                                    <span class="title-en">Data Visualization</span>
                                    <span class="title">시각화 분석</span>
                                </div>
                            </div>
                            <figcaption class="card-content">시각화 분석 툴 연동 및 기능 구현</figcaption>
                        </figure>
                    </swiper-slide>
                </swiper>
            </div>
        </div>
    </article>
</template>

<script>
import {Swiper, SwiperSlide} from 'swiper/vue/swiper-vue';
import {Autoplay} from 'swiper';
export default {
    name: 'MainFoothold',
    components: {
        Swiper,
        SwiperSlide,
    },
    setup() {
        return {
            modules: [Autoplay],
        };
    },
};
</script>

<template>
    <article class="main-section section-service">
        <div class="section-component">
            <div class="section-content section-component">
                <h3 class="service-title">
                    (<em class="title-keyword">{{ service }}</em
                    >) 서비스가 궁금해요.
                </h3>
                <p class="service-description">
                    소프트웨어 제품개발에 필요한 데이터 조달과 비즈니스 인사이트를 <br />발굴할 수 있는 기초 분석환경을 제공하고, 인공지능 알고리즘과 모델개발을
                    위한 고성능 환경을 지원합니다. <br />궁금한게 있다면 언제든 확인하세요.
                </p>
                <div class="service-keywords">
                    <button
                        v-for="(item, index) in services"
                        class="btn btn-white btn-keyword"
                        :key="index"
                        @mouseover="showService(item.title)"
                        @mouseleave="showService(' ')"
                        @click="goService(item.name)">
                        <span class="text">{{ item.title }}</span>
                    </button>
                </div>
            </div>
        </div>
    </article>
</template>

<script>
import {ref} from 'vue';
import {useRouter} from 'vue-router';

export default {
    name: 'MainService',
    components: {},
    setup() {
        const router = useRouter();
        const service = ref(' ');
        const services = ref([
            {title: '애널리틱', name: 'AnalyticsGuid'},
            {title: '시각화', name: 'VisualizationGuid'},
            {title: 'EDA', name: 'Glue'},
        ]);

        const showService = selected => {
            service.value = selected;
        };

        const goService = name => {
            if (name === 'Glue') {
                window.open('https://aws.amazon.com/ko/glue/');
            } else {
                router.push({name});
            }
        };

        return {
            service,
            services,
            showService,
            goService,
        };
    },
};
</script>

<template>
    <article class="main-section section-mainbanner">
        <div class="banner-container">
            <!--            :navigation="{-->
            <!--            prevEl: '.main-banner-prev',-->
            <!--            nextEl: '.main-banner-next',-->
            <!--            }"-->
            <swiper class="banner-wrapper" :modules="modules" effect="fade">
                <swiper-slide class="banner-item">
                    <div class="banner-image">
                        <video ref="bannerVideo" autoplay muted>
                            <source src="@/assets/video/home_main_banner2.mp4" type="video/mp4" />
                        </video>
                    </div>
                    <div class="banner-content banner-component">
                        <h3 class="content-title">SW융합 신산업 육성의 <br />데이터 허브 플랫폼</h3>
                        <p class="content-text">인공지능 기술과 데이터 허브 플랫폼으로 비즈니스 인사이트 역량을 강화합니다.</p>
                    </div>
                </swiper-slide>
                <!--                <swiper-slide class="banner-item">-->
                <!--                    <div class="banner-image">-->
                <!--                        <img src="" alt="" />-->
                <!--                    </div>-->
                <!--                    <div class="banner-content banner-component">-->
                <!--                        <h3 class="content-title">SW융합 신산업 육성의 <br />데이터 허브 플랫폼 2</h3>-->
                <!--                        <p class="content-text">인공지능 기술과 데이터 허브 플랫폼으로 비즈니스 인사이트 역량을 강화합니다.</p>-->
                <!--                    </div>-->
                <!--                </swiper-slide>-->
                <!--                <swiper-slide class="banner-item">-->
                <!--                    <div class="banner-image">-->
                <!--                        <img src="" alt="" />-->
                <!--                    </div>-->
                <!--                    <div class="banner-content banner-component">-->
                <!--                        <h3 class="content-title">SW융합 신산업 육성의 <br />데이터 허브 플랫폼 3</h3>-->
                <!--                        <p class="content-text">인공지능 기술과 데이터 허브 플랫폼으로 비즈니스 인사이트 역량을 강화합니다.</p>-->
                <!--                    </div>-->
                <!--                </swiper-slide>-->
            </swiper>
        </div>
        <!--        <div class="banner-nav">-->
        <!--            <div class="nav-wrapper">-->
        <!--                <button class="btn-nav main-banner-prev"><i class="icon-nav-prev"></i></button>-->
        <!--                <button class="btn-nav"><i class="icon-nav-pause"></i></button>-->
        <!--                <button class="btn-nav main-banner-next"><i class="icon-nav-next"></i></button>-->
        <!--            </div>-->
        <!--        </div>-->
    </article>
</template>

<script>
import {Swiper, SwiperSlide} from 'swiper/vue/swiper-vue';
import {EffectFade, Navigation} from 'swiper';
import {ref} from 'vue';

export default {
    name: 'MainBanner',
    components: {
        Swiper,
        SwiperSlide,
    },
    setup() {
        const bannerVideo = ref(null);
        // const player = ref(null);

        // onMounted(() => {
        //     if (bannerVideo.value) {
        //         player.value = videojs(bannerVideo.value, {
        //             playsinline: true,
        //             loop: true,
        //             autoplay: true,
        //             muted: true,
        //         });
        //     }
        // });
        //
        // onBeforeUnmount(() => {
        //     if (player.value) {
        //         player.value.dispose();
        //     }
        // });

        return {
            bannerVideo,
            modules: [Navigation, EffectFade],
        };
    },
};
</script>

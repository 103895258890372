import store from '@/store';

export const domainCode = {
    BOARD_TYPE_NOTICE: '1002001',
    BOARD_TYPE_BUSINESS: '1002002',
    BOARD_TYPE_REFERENCE: '1002003',
    BOARD_TYPE_REPORT: '1002004',
    BOARD_TYPE_CONSULTING: '1002005',
    BOARD_TYPE_CONVERSION: '1002006',
    BOARD_TYPE_VISUALIZATION: '1002007',
    BOARD_TYPE_ANALYTICS: '1002008',

    META_TYPE_S3: '1003001',

    BOARD_GB_ALL: '1004001',
    BOARD_GB_JEJU: '1004002',
    BOARD_GB_DAEGU: '1004003',
};

export const getDcdName = dcd => {
    const target = store.state.common.dcds.find(x => x.dcd === dcd);
    return target ? target.dcdKoVal : '-';
};
